#techStack {
    padding-top: 10rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stackflex {
    width: 80vw;
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    justify-items: center;
    margin: 2% 0;
}

.stackflex img {
    margin: 4% 0;
}

.stackflex1 {
    margin-top: 5%;
}

.stackStyle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    cursor: pointer;
    padding: 2%;
    margin-bottom: 28px;
    text-align: center;
    font-size: 0.8em;
}

.stack {
    width: 5vw;
    display: flex;
}

@media only screen and (max-width: 600px) {
    #techStack {
        padding-top: 4rem;
        height: 100%;
    }

    .stackflex {
        width: 100%;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .stack {
        width: 10vw;
        padding: 4%;
    }

    .stackStyle {
        font-size: 0.5em;
    }
}