#myprojects {
    height: 100%;
    padding-top: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
}


.project-desc{
    text-align: justify;
}

@media only screen and (max-width: 600px) {
    #myprojects {
        padding-top: 4rem;
    }
}