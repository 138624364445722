.nav-panel {
    display: none;
}

.menu span:hover {
    background: linear-gradient(90deg, #1487cc -2.06%, #E70FAA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    transition: 0.5s;
}


@media only screen and (max-width: 600px) {
    
    .nav-panel__container__logo {
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
        padding: 4%;
        border-radius: 10px;
    }
    .nav-panel {
        position: fixed;
        right: 0;
        left: 0;
        bottom: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        z-index: 100;
    }

    .nav-panel__container {
        /* box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.25); */
        background: #ffffff40;
        box-shadow: inset 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(4px);
        padding: 10px 4px;
        border-radius: 10px;
        width: 80vw;
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        justify-items: center;
    }

    .menu {
        width: 15vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: none;
        background: inherit;
    }

    .nav-panel__container__logo span {
        font-size: 0.8em;
        font-weight: bold;
    }

    .menu:active span {
        background: linear-gradient(90deg, #1487cc -2.06%, #E70FAA 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        transition: 0.5s;
    }
}