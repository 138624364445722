.project {
    cursor: default;
    display: flex;
    flex-direction: column;
    height: 50vh;
    width: 20vw;
    margin: 2% 0;
    padding: 0 0 0.5rem;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5%;
    margin-bottom: 5vh;
    transition: transform 0.5s ease;
}

.project:hover {
    transform: scale(1.05);
    transition: 0.5s;
}

.project-img img {
    width: 20vw;
    border-radius: 20px 20px 0 0;
}

.project-desc {
    line-height: 2;
    padding: 1% 4%;
}

.project-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.project-heading {
    font-family: poppins-bold;
    color: #000;
    text-align: center;
}

.project-links {
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: space-around;
}

.project-link {
    height: 32px;
    /* box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.1); */
    box-shadow: inset 0 0 10px 0 rgba(0, 0, 255, 0.2);
    border: 2px solid transparent;
    border-radius: 8px;
    padding: 0.2rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-links a {
    text-decoration: none;
    background: linear-gradient(90deg, #1487cc -2.06%, #E70FAA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font: poppins-bold;
    font-weight: bold;
}

.github {
    display: contents;
}

.project-tech {
    font-size: 0.8rem;
    display: inline-block;
    margin: 0.5rem 0.5rem 0 0;
    border: 1px solid #ccc;
    padding: 0 0.5rem;
    border-radius: 8px;
    box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.1);
    color: #000;
}

.project-description {
    font-size: 0.8rem;
    color: #666666;

}


@media only screen and (max-width: 600px) {
    .project {
        width: 100%;
        margin-top: 10%;
        margin-bottom: 0;
    }

    .project-img img {
        width: 100%;
    }
}