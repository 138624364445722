#home {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100vh;
}



@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.Typewriter__cursor {
    color: #000000;
    animation: blink 1s step-start 0s infinite;
}


@keyframes waveHand {

    0%,
    100% {
        transform: rotate(0deg);
    }

    20%,
    60% {
        transform: rotate(-20deg);
    }

    40%,
    80% {
        transform: rotate(10deg);
    }
}

.hi {
    display: inline-block;
    animation: waveHand 2s ease-in-out infinite;
}

#home h1 {
    font-size: 4rem;
    font-family: poppins-bold;
}

#home h2 {
    font-size: 3.5rem;
    font-weight: bold;
    font-family: poppins-bold;
}

#home h3 {
    font-size: 1.5rem;
    font-family: poppins-reg;
}

.mobile {
    display: none;
}

.heading {
    background: linear-gradient(90deg, #1487cc -2.06%, #E70FAA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: poppins-bold;
}





/* .profile-img img ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 25vw;
    height: 25vw;
    background: linear-gradient(90deg, #1487cc -2.06%, #E70FAA 100%);
    border-radius: 100%;
    z-index: -1;
    translate: (-50%, -50%);
} */

.profile-img img {
    width: 40vw;
    filter: drop-shadow(8px 4px 10px rgba(0, 0, 0, 0.5));
    border-radius: 5%;
}

.role {
    font-size: 0.4rem;
    font-weight: bold;
    font-family: poppins-bold;
    color: #666666;
}

@media only screen and (max-width: 600px) {
    #home {
        flex-direction: column;
        justify-content: center;
        height: 100vh;
    }

    #home h1 {
        font-size: 1.8rem;
        text-align: center;
    }

    #home h2 {
        font-size: 2rem;
    }

    #home h3 {
        margin-top: 50px;
    }

    .profile-img img {
        width: 96vw;
    }

    .pc {
        display: none;
    }

    .mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}