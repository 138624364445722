#about-me {
    height: 100%;
    width: 80vw;
    display: flex;
    align-items: center;
    padding-top: 10rem;
}

.about {
    display: flex;
    justify-content: space-between;
    padding: 2%;
    line-height: 2;
}

.clg {
    display: inline;
    font-size: 0.8em;
}

.margin-top {
    margin-top: 2%;
}

.desc {
    height: auto;
    padding: 1%;
    /* background-color: #fff; */
}

.desc p {
    text-align: justify;
}

.tech-stack {
    width: 15%;
}


.bullets {
    text-align: justify;
}

@media only screen and (max-width: 600px) {

    #about-me {
        height: auto;
        width: 100%;
    }
}