#projects{
    margin: 5% 0;
    width: 80vw;
}

.myprojects{
    margin: 2% 0;
    display: grid;
    grid-template-columns: repeat(3,minmax(0,1fr));
    justify-items: center;
}

@media only screen and (max-width: 600px){
    .myprojects{
        grid-template-columns: repeat(1,minmax(0,1fr)); 
    }
}