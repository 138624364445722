@font-face {
    font-family: poppins-reg;
    src: url(./fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: poppins-bold;
    src: url(./fonts/Poppins-Bold.ttf);
}

* {
    margin: 0;
    padding: 0;
    font-family: poppins-reg;
}

body {
    height: 100vh;
    color: #000;
    background-color: #F5F5F5;
    margin: 0 10%;
}

/* h1 {
    color: #42446E;
} */

p {
    color: #666666;
}

a {
    color: #000;
}

.centre {
    text-align: center;
}

.bold {
    font-family: poppins-bold;
}

.black {
    color: #000;
}


@media only screen and (max-width: 600px) {
    body {
        margin: 0 5%;
    }
}